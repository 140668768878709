
.footerContainer {
    background-color: #1786ff;
    border-top: 1px solid #ccc;

}

.footer {
    color:#333;
}


.footerHeading {
    color:#dddddd;
    font-weight: bold;
    font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    margin-bottom: 10px;
}
.footerlist {
    list-style-type: none;
    padding:0px;
    margin:0px auto;
    width:100%;

}

.footerlist li {
    padding:20px;
    padding-left: 20px;
    display: inline-block;
    color:#fff;
    font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
.footerlist li a {
    color:#fff;
    font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    text-decoration: none;
}
.footerlist li a:hover {

}

.footerLogo {
    font-weight: bold;
    color:#dddddd;
    font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 30px;
}

.footerAppstoreIcons img {
    height:38px;
    margin:10px 10px 10px 0px;
}
