
.pricingPageWrapper {
    background-color:#f7f7f7;
}

.pricingColumn {
    border:1px solid #ccc;
    padding:20px;
    background: #fff;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.pricingColumn .packageTitle {
    font-family: "Graphik-Bold", "Open Sans", "Lato", "proxima-nova", "Helvetica Neue", Arial, sans-serif;
    font-size: 2.5rem !important;
    color:#000 !important;
}
.pricingColumn .packageSubtitleWrapper {
    display: flex;
    flex-direction: row;
    flex:1;
    align-items: center;
    font-size: 2.2rem !important;
    margin: 0px 0px 0px 0px;
}
.pricingColumn .packageSubtitleWrapper .packageSubtitle {
    font-family: "Graphik-Bold", "Open Sans", "Lato", "proxima-nova", "Helvetica Neue", Arial, sans-serif;
    font-size: 2.4rem !important;
    color:#000 !important;
    line-height: 2.6rem;
}
.pricingColumn .packageSubtitleWrapper .text-muted {
    font-size:1rem;
    font-family: "Graphik", "Lato", "proxima-nova", "Helvetica Neue", Arial, sans-serif;
    line-height: 1rem;
    flex:1;
    padding: 20px;
}
.pricingColumn .packageSubtitleWrapper .text-muted div {
    padding:0px 0px 4px 0px;
}
.minidivider {
    width:80px;
    height:2px;
    background-color: red;
    margin-top: 10px;
    margin-bottom: 10px;
}
.featureDivider {
    height:1px;
    background-color: #ccc;
    margin-bottom: 20px;
}
.pricingCheck {
    color:#00CC00;
    margin-right: 6px;
    align-self: start;

}
.pricingFeature {

    font-family: "Graphik", "Lato", "proxima-nova", "Helvetica Neue", Arial, sans-serif;
    letter-spacing: -.5px;
    padding:4px 0px;
}

.highlightColumn {
    background-color: green;
    padding:1px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}
.pricingFeature .text-muted {
    display: block;
    font-size:0.8rem;

}

.pricingActionWrapper {
    padding:10px 0px;
    margin-bottom: 20px;
}

.pricingActionWrapper button{
    width:100%;
    background-color: #fff !important;
    color:#1786ff !important;
}
.pricingActionWrapper .highlightedButton{
    background-color: #1786ff !important;
    color:#fff !important;
}
.pricingActionWrapper button:hover{
    width:100%;
    background-color: #1786ff !important;
    color:#fff !important;
}
